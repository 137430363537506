<script lang="ts">
	export let direction: 'left' | 'right' | 'top' | 'bottom' = 'left';
	export let darkMode = false;
	export let className = '';
	// icon is left by default
	switch (direction) {
		case 'right':
			className = 'transform rotate-180';
			break;
		case 'top':
			className = 'transform rotate-90';
			break;
		case 'bottom':
			className = 'transform -rotate-90';
			break;
		default:
			className = '';
			break;
	}
</script>

<span class={`inline-block ${className}`}>
	<svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path
			d="M8 15L1 8L8 1"
			stroke={darkMode ? 'white' : '#0F0F0F'}
			stroke-linecap="round"
			stroke-linejoin="round"
		/>
	</svg>
</span>
